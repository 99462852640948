export default {
  banner: {
    title: "Helping foster parents and their kids connect.",
    image: {
      src: require("assets/banner_foster.jpg"),
      placeholder: require("assets/banner_foster_thumb.jpg"),
      height: 336,
      width: 736,
    },
  },
  facts: {
    title: "",
    items: [
      {
        icon: "alert",
        label: "",
        sublabel: "",
      },
      {
        icon: "whistle",
        label: "",
        sublabel: "",
      },
      {
        icon: "whistle",
        label: "",
        sublabel: "",
      },
      {
        icon: "mask",
        label: "",
        sublabel: "",
      },
    ],
  },
  download: {
    header: "Foster care conversation cards",
    title: "Start the conversation",
    desktop: {
      label: "Download digital cards",
      pdf: "/FosterCareConversationCards_Digital.pdf",
    },
    mobile: {
      label: "Download print cards",
      pdf: "/FosterCareConversationCards_Print.pdf",
    },
    body: [
      {
        title: "Spark a conversation",
        subtitle:
          "If you’re wondering how to get started, and get past one-word answers, these conversation starters can help. Click either of the above buttons to download a digital deck of 30 conversation starters.\n\n You can print this shuffle deck out and use it around the kitchen table, in the car or anywhere you want to strike up a conversation with a teen or young adult, by clicking the printer icon in the top/right corner of your browser window once you have clicked through to the next screen.\n\n Or, you can download this PDF to your computer or phone, by clicking download/save icon in the top/right corner of your browser window once you have clicked through to the next screen.",
      },
    ],
    image: {
      src: require("assets/foster.png"),
      height: 380,
      width: 379,
    },
  },
  article: {
    image: {
      src: require("assets/article_caregiver.png"),
      placeholder: require("assets/article_caregiver_thumb.png"),
      height: 416,
      width: 592,
    },
    title: "Caregiving is more than just giving care",
    subtitle:
      "Acting as a caregiver for a loved one is both an incredibly rewarding, and challenging undertaking.\n\nWhile the role can come with many gratifying moments, it’s also often accompanied by stress, fatigue, and its fair share of conflicting emotions. ",
    link: {
      label: "Read article >",
      pdf: "/Combating_Caregiver_Fatigue_Article.pdf",
    },
  },
};
